.genus {
    width: 90%;
    margin-top: 2%;
    margin-left: 4.5%;
}

.genus_identification {
    width: 89%;
    margin-top: 3%;
}

.species {
    width: 90%;
    margin-top: 2%;
    margin-left: 4.5%;
}

.species_identification {
    width: 89%;
    margin-top: 3%;
    margin-bottom: 7%;
}

.siteFilters {
    width: 50%;
    margin-left: 2%;
}
