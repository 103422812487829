/*------------------------------------------
  Responsive Grid Media Queries - 1280, 1024, 768, 480
   1280-1024   - desktop (default grid)
   1024-768    - tablet landscape
   768-480     - tablet 
   480-less    - phone landscape & smaller
--------------------------------------------*/

.CollectionMenuList {
  margin-top: 6.5% !important;
  margin-left: 14.5% !important;
}

.headline {
  font-style: bold;
  margin-top: 8px;
}

.name {
  position: absolute;
  right: 8%;
  font-style: bold;
  margin-top: 8px;
}

.refresh {
  position: absolute !important;
  right: 77%;
}

.delete {
  right: 2%;
}

.logoutLink {
  color: red !important;
}

.menu {
  color: black;
}

.MenuList {
  margin-top: 4%;
  margin-left: 1%;
}

.MenuList a {
  text-decoration: none;
  color: inherit;
}

.navbar {
  background-color: #4d79ff;
  flex-direction: row;
  color: black;
  font-size: 120%;
  display: flex;
  width: 100%;
}

.navbarTesting {
  background-color: #c32c2c;
  flex-direction: row;
  color: black;
  font-size: 120%;
  display: flex;
  width: 100%;
}

.logedInNavbar {
  background-color: #4d79ff;
  flex-direction: row;
  color: black;
  font-size: 120%;
  display: flex;
  width: 100%;
  position: relative;
}

.logedInNavbarTesting {
  background-color: #c32c2c;
  flex-direction: row;
  color: black;
  font-size: 120%;
  display: flex;
  width: 100%;
  position: relative;
}


.notificationsIcon {
  position: absolute !important;
  right: 0;
}

.rulesIcon {
  position: absolute !important;
  right: 2%;
}

.newRuleIcon {
  position: absolute !important;
  right: 4%;
}

.notificationsCounter {
  background-color: red;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  position: absolute;
  top: 29%;
  left: 55%;
}

.readNotificationBtn {
  width: 80%;
}

@media all and (max-width:2600px) and (min-width: 1600px) {
  .CollectionMenuList {
    margin-left: 15.5% !important;
    margin-top: 6.5%;
  }

  .headline {
    margin-top: 10px !important;
  }
}

@media all and (max-width: 1024px) and (min-width: 768px) {
  .CollectionMenuList {
    margin-left: 17% !important;
    margin-top: 6.5%;
  }

  .headline {
    margin-top: 10px !important;
  }

  .navbar {
    font-size: 0.9rem !important;
  }
}

@media all and (max-width: 768px) and (min-width: 200px) {
  .headline {
    margin-top: 15px !important;
    font-size: 14px !important;
  }

  .name {
    font-size: 14px !important;
    margin-top: 15px !important;
    right: 14% !important;
  }

  .refresh {
    right: -30% !important;
  }

  .rulesIcon {
    right: -20% !important;
  }

  .newRuleIcon {
    right: -20% !important;
  }

  .navbar {
    font-size: 0.8rem !important;
  }
}