.filter {
    margin-left: 4%!important;
    margin-top: 0.5%;
    width: 90%;
}

.hiddenButtons {
    margin-top: 2%!important;
    width: 100%;
    height: 6%;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
}

.verifiedAll {
    width: 45%!important;
    margin-left: 2px!important;
    margin-top: 4px!important;
    text-transform: lowercase!important;
    display: inline-flex;
}

.createCollections {
    margin-top: 3%!important;
    margin-left: 1%!important;
    margin-bottom: 5%!important;
    width: 185px!important;
}

.createSpread{
    margin-top: 2%!important;
    margin-left: 4%!important;
    margin-bottom: 5%!important;
    width: 140px!important;
}

.createEmptyCollection {
    margin-top: 3%!important;
    margin-left: 5%!important;
    margin-bottom: 5%!important;
    width: 185px!important;
}

.moveLandmarks {
    margin-top:2%!important;
    margin-left: 3%!important;
    margin-bottom: 5%!important;
}

.doneSpreads{
    margin-top: 2%!important;
    margin-left: 3%!important;
    margin-right: 2%!important;
    margin-bottom: 5%!important;
}

.addSite {
    text-transform: lowercase!important;
    margin-left: 40%!important;
    margin-top: 1%!important;
}

.addTest {
    text-transform: lowercase!important;
    margin-top: 7%!important;
}

.createTest {
    text-transform: lowercase!important;
    margin-top: 10%!important;
    margin-left: 5%;
    width: 90%!important;
}

.createSite {
    margin-top: 10%!important;
    margin-bottom: 25%!important;
}

.submit {
    width: 15%!important;
    margin-top: 8%!important;
}

.next {
    width: 10%!important;
    margin-top: 6%!important;
    margin-left: 45%!important;
}

.generateReport {
    width: 70%!important;
    margin-left: 7%!important;
    height: 70%!important;
    margin-top: 3%!important;
    margin-left: 15%!important;
}

.identification {
    margin-top: 3%!important;
    margin-left: 20%!important;
    height: 70%!important;
    width: 37%!important;
}

.zoomSelected {
    display: flex;
    flex-direction: column;
    position: relative;
    left: 14%;
    width: 101%;
    margin-top: 3%!important;
    margin-bottom: 4%!important;
    margin-left: 36%!important;
    z-index: 0;
    background-color: aliceblue!important;
    transform: translateX(-50%);
}

.mapType {
    display: flex;
    flex-direction: column;
    position: relative;
    left: 37.5%;
    width: 101%;
    margin-top: -26%!important;
    margin-left: 12%!important;
    z-index: 0;
    background-color: aliceblue!important;
    transform: translateX(-50%);
}

@media all and (max-width: 768px) and (min-width: 480px) {
    .zoomSelected {
        flex-direction: row!important;
        font-size: 0.4rem!important;
        margin-left: 2%!important;
        margin: 3px!important;

    }

    .mapType {
        flex-direction: row!important;
        font-size: 0.4rem!important;
        margin-left: 2%!important;
        margin: 3px!important;
    }

    .firstUnidentifiedInsect {
        font-size: 0.65rem!important;
    }

    .pdf {
        font-size: 0.65rem!important;
    }

    .locationMap {
        font-size: 0.65rem!important;
    }

    .createEmptyCollection{
        width: 80px!important;
        font-size: 0.65rem!important;
    }

    .createCollections{
        width: 80px!important;
        font-size: 0.65rem!important;
    }

    .verifyButtons{
        display: flex!important;
    }
}

@media all and (max-width: 1024px) and (min-width: 768px) {
    .zoomSelected {
        flex-direction: row!important;
        font-size: 0.4rem!important;
        margin-left: 2%!important;
        margin: 3px!important;
        box-sizing: content-box!important;
    }

    .mapType {
        flex-direction: row!important;
        font-size: 0.6rem!important;
        margin-left: 2%!important;
        margin: 3px!important;
        box-sizing: content-box!important;
    }
    .firstUnidentifiedInsect {
        font-size: 0.8rem!important;
    }

    .pdf {
        font-size: 0.8rem!important;
    }

    .locationMap {
        font-size: 0.8rem!important;
    }

    .createEmptyCollection{
        width: 100px!important;
    }

    .createCollections{
        width: 100px!important;
    }

    .verifyButtons{
        display: flex!important;
    }
}