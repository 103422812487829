.checkboxLabel{
    font-size: 0.7rem!important;
}

.secondOpinionFilter {
    margin-top: -1%!important;
    margin-left: 13%!important;
    width: 10%;
}

.trap {
    margin-left: 15%!important;
}

.unverifiedFilter {
    margin-top: -1%!important;
    margin-left: 13%!important;
    width: 10%;
}