
.basicInfo {
    margin-top: 8%!important;
    display: flex;
    flex-direction: column;
    font-size: 120%;
    padding-top: 3%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 5%;
    border-style: solid;
    border-radius: 1em;
    border-color: #deeaee;
}

.checkBoxes {
    margin-top: -5%;
}

.disabled {
    pointer-events: none;
    opacity: 0.3;
}

.fed {
    padding-left: 7%;
}

.firstImage {
    width: 48%!important;
}

.identification {
    display: flex;
    flex-direction: row;
    border-style: solid;
    border-radius: 1em;
    border-color: #deeaee;
    margin-top: 2vh;
    justify-content: space-around;
}

.identificationCenter {
    width: 73%!important;
    display: flex;
    flex-direction: column;
    margin-left: -15%!important;
}

.identificationInfo {
    padding-top: 5%;
    font-size: 120%;
}

.images {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.rightPage {
    padding-left: 2%!important;
    width: 80%!important;
}

.insectInfo {
    width: 22%!important;
    padding-left: 2%;
    height: 100%!important;
}

.lastRow {
    display: flex;
    flex-direction: row;
}

.navigateBetweenInsects {
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 30%;
    margin-top: 1%;
}

.neededSecondOpinion {
    font-size: 120%;
    color: red;
    padding-top: 5%;
}

.pageIdx {
    font-size: 110%;
    border-style: solid;
    border-color: #deeaee;
    background-color: #deeaee;
    width: 110%;
    border-radius: 3rem;
    text-align: center;
}

.pdf {
    text-transform: lowercase!important;
    white-space: nowrap;
}

.returnToCollection {
    margin-left: 36%!important;
}

.rightSideIdentification {
    width: 35px;
    margin-left: -20%;
}

.secondImage {
    width: 48%!important;
    margin-left: 2%!important;
}

.smartGuide {
    margin-top: 5% !important;
    width: 100%;
}

.spinner {
    position: absolute;
    right: 50%;
    bottom: 45%;
}

.verifiedIdentification {
    display: flex;
    flex-direction: row;
    border-style: solid;
    border-radius: 1em;
    border-color: green;
    margin-top: 2vh;
    justify-content: space-around;
}

.wrapper {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: row;
}