/*------------------------------------------
  Responsive Grid Media Queries - 1280, 1024, 768, 480
   1280-1024   - desktop (default grid)
   1024-768    - tablet landscape
   768-480     - tablet 
   480-less    - phone landscape & smaller
--------------------------------------------*/

/*(max-width: 1280) and (min-width: 1024)*/
.filters {
    overflow-y: scroll;
    overflow: auto;
    height: 91vh;
    width: 13.3rem;
}

.checkVerifiedComp {
  margin-top: -5%;
}

@media all and (max-width: 1024px) and (min-width: 768px) {
  .filters{
    width: 9rem!important;
  }
}

@media all and (max-width: 768px) and (min-width: 480px) {
  .filters{
    width: 9rem!important;
  }
}
