/*------------------------------------------
  Responsive Grid Media Queries - 1280, 1024, 768, 480
   1280-1024   - desktop (default grid)
   1024-768    - tablet landscape
   768-480     - tablet 
   480-less    - phone landscape & smaller
--------------------------------------------*/

/*(max-width: 1280) and (min-width: 1024)*/
.filterFields {
    width: 90%;
    margin-top: 3%;
    margin-left: 4.2%;
    height: 40px;
}

.typeField {
    width: 120%;
    margin-top: 1%;
    margin-left: 0%;
}

.identification {
    width: 89%;
    margin-top: 5%;
}

.runs {
    margin-top: 5%;
}

.siteFilters {
    width: 50%;
    margin-left: 2%;
}

.areaCombobox {
    width: 90%;
    margin-left: 2%;
}

.userDateComboBox {
    width: 50%;
    margin-left: 2%;
    margin-bottom: 10px;
}

.editCollection {
    width: 20%;
    margin-top: 2%;
}

.vials {
    width: 35% !important;
    margin-top: 2%;
    margin-left: 37%;
}

.addTest {
    width: 115%;
    margin-left: -7%;
}

.addResult {
    margin-top: 5%;
    width: 115%;
    margin-left: -7%;
}

.run {
    margin-top: 12%;
    width: 80%;
    margin-left: -1%;
}

.searchCombo{
    width: 200px;
    position: absolute;
    margin-left: 1%;
    margin-top: 5%;
    background-color: white!important;
}

@media all and (max-width: 1024px) and (min-width: 768px) {
    .title {
        font-size: 100% !important;
    }

    .nameField {
        width: 50% !important;
    }

    .passwordField {
        width: 80% !important;
    }

    .login {
        width: 55% !important;
    }
}

@media all and (max-width: 768px) and (min-width: 480px) {
    .title {
        font-size: 90% !important;
    }

    .nameField {
        width: 60% !important;
    }

    .passwordField {
        width: 60% !important;
    }

    .login {
        width: 55% !important;
    }
}