.filters {
    border-right: solid 2px gray;
    display: flex;
    flex-direction: row;
}

.leftToIdentify {
    margin-right: 1%;
}

.parentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
}

.tableButton {
    position: absolute;
    bottom: 20px;
    opacity: 0;
    transition: opacity 0.3s ease;
    width: auto;
    padding: 0;
}

.tableButton button {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tableButton svg {
    margin-left: -30px;
}

.parentContainer:hover .tableButton {
    opacity: 1;
}

.tableContainer:hover .tableButton {
    opacity: 0;
}

.map {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
}

.minimizebutton {
    border-radius: 5em;
    display: flex;
    justify-content: center;
    padding-left: 24px !important;
    margin-left: 100%;
    background-color: #c0cef4;
    align-items: center !important;
}

.minimizeIcon {
    width: 30px !important;
    z-index: 5;
    align-items: center;
    display: flex;
    justify-content: center;
}

.maximizeIcon {
    width: 30px !important;
    z-index: 5;
    align-items: center;
    display: flex;
    justify-content: center;
}

.table {
    width: 100%;
}

.wrapper1 {
    display: flex;
    flex-direction: row;
    height: 91%;
    width: 100%;
    overflow: auto;
}

.wrapper2 {
    display: flex;
    flex-direction: row;
}

@media all and (max-width: 768px) and (min-width: 480px) {
    .map {
        width: 80% !important;
    }
}

@media all and (max-width: 1024px) and (min-width: 768px) {
    .map {
        width: 82% !important;
    }
}