.dialogContent {
    display: flex;
    flex-direction: column; 
}

.container {
    display: flex!important;
    flex-direction: column!important;
    justify-content: space-between;
}

.container > * {
    margin-bottom: 10px; /* Adjust the margin as needed */
}