.siteInfo {
    font-size: 1.3em;
    line-height: 1.7;
    margin-left: 7%;
}

.infoWindowContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nextDirections {
    margin-left: 10px; /* Adjust as needed */
}
.cellPhone{
    margin-top: 2px;
}