.survey-container {
  direction: rtl; 
  text-align: right;
  padding: 20px;
  max-width: 850px;
  margin: 0 auto;
}

h1 {
  margin-bottom: 20px;
}

h5 {
  margin-bottom: 20px;
  line-height: 1.6;
}

.rating-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.rating-label {
  font-size: 1.2rem;
  margin: 0 1rem;
}

.rating-label.left {
  margin-left: 0; 
}

.rating-label.right {
  margin-right: 0; 
}

.rating-container {
  display: flex;
  align-items: center;
  margin: 0 1rem;
}

.rating-star {
  position: relative;
  font-size: 3.5rem;
  cursor: pointer;
  margin: 0 0.1rem;
}

.rating-star input {
  display: none;
}

.star {
  color: gray; /* Default color for unselected stars */
}

.star.green {
  color: green; /* Color for selected stars 1-2 */
}

.star.orange {
  color: orange; /* Color for selected stars 3 */
}

.star.red {
  color: red; /* Color for selected stars 4-5 */
}

.comments-section {
  margin-bottom: 20px;
}

textarea {
  width: 100%;
  height: 100px;
  margin-top: 0.5rem;
  padding: 0.5rem;
  font-size: 1rem;
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}