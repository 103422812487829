.wrapper1 {
    display: flex;
    width: fit-content;
    flex-direction: row;
}

.insectInfo {
    font-size: 1.1em;
    line-height: 1.7;
}

.virusInfo {
    font-size: 1.1em;
    line-height: 1.7;
    color: crimson;
}

.leftI {
    top: 50%;
    transform: translate(0, -50%);
}

.rightI {
    top: 50%;
    transform: translate(0, -50%);
}

.percentsH {
    font-size: 1em;
    line-height: 2.6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-style: solid;
    border-color: #11698E;
    width: 3vw;
    margin-left: 20%;
}

.iconsDiv {
    margin-top: 0%;
    margin-right: 0%;
    margin-left: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.iconsDiv>* {
    margin-bottom: 0.8px;
    /* Adjust the margin value */
}

.pageNumber {
    margin-left: 100%;
}

@media all and (max-width: 1024px) and (min-width: 768px) {
    .insectInfo {
        font-size: 0.9em !important;
        line-height: 1.1 !important;
    }
}

@media all and (max-width: 768px) and (min-width: 480px) {
    .insectInfo {
        font-size: 0.8em !important;
        line-height: 0.9 !important;
    }
}