.slider {
    width: 85%;
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1px;
}

.label{
    font-style: italic;
    font: weight 500px;;
}