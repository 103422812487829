.frame {
    width: 100%;
    margin-top: 2%;
    align-self: center;
}

.trapEventId {
    width: 15%;
    margin-left: 2%;
    margin-top: 5%;
}

.identification {
    width: 15%;
    margin-left: 2%;
    margin-top: 2%;
}

.insectsNum {
    width: 15%;
    margin-left: 5%;
    margin-top: 1.5%;
}

.identificationFrame {
    margin-top: 2%!important;
    display: flex;
    flex-direction: row;
}

.addInsects {
    top: 32%;
    left: 30%;
}

.insectsInv {
    font-size: 1.7em;
    line-height: 1.7;
    margin-top: 1%;
    margin-left: 2%;
}

.save {
    margin-left: 60%!important;
    margin-top: 3%!important;
    width: 100%!important;
}

.saveFrame {
    width: 10%!important;
}

.edit {
    margin-left: 1%!important;
    margin-top: 3%!important;
    width: 100%!important;
}

.editFrame {
    width: 10%!important;
}

.wraper {
    margin-top: 2%!important;
    margin-left: 2%!important;
    display: flex;
    flex-direction: row;
}

.newInfo {
    display: flex;
    flex-direction: row;
    width: 98%!important;
    margin-left: 2%;
    margin-top: 4%;
}

.pickupDate {
    margin-left: 29%!important;
    width: 80%;
}