.wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.wrapper1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 4%;
    margin-left: 2%;
}

.wrapper2 {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.wrapper3 {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.alert {
    margin-bottom: 10%;
    margin-top: 10%;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-style: solid;
    border-radius: 3em;
    border-color: #11698E;
    margin-top: 2%;
    margin-left: 1.2%;
    width: 150%;
}

.createSheets {
    display: flex;
    flex-direction: row;
    margin-top: 2%;
}

.purpose {
    width: 48%;
    margin-left: 1%;
    margin-right: 4%;
    margin-top: 5%;
}

.type {
    width: 48%;
    margin-left: 3%;
    margin-right: 8%;
    margin-top: 5%; 
}

.headline{
    font-size: 1.3em;
}

@media all and (max-width: 768px) and (min-width: 480px) {
    .type{
        width: 25%!important;
    }
        
    .purpose{
        width: 25%!important;
    }
    .headline{
        font-size: 1.0em;
    }
}

@media all and (max-width: 1024px) and (min-width: 768px) {
    .type{
        width: 30%!important;
    }
    
    .purpose{
        width: 30%!important;
    }
    .headline{
        font-size: 1.1em;
    }
}