.tableContainer {
    border-style: ridge;
    height: 150px;
    width: 95%!important;
}

.tableCell {
    padding-top: 3px!important;
    padding-bottom: 2px!important;
    white-space: nowrap!important;
}