.wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.wrapper1 {
    display: flex;
    width: 100%;
    height: 6%;
    margin-top: 4%;
    margin-left: 2%;
}

.wrapper2 {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.wrapper3 {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.alert {
    margin-bottom: 10%;
    margin-top: 10%;
}

.checkBoxs {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
}

.checkBoxsMobile {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-style: solid;
    border-radius: 3em;
    border-color: #11698E;
    margin-top: 2%;
    margin-left: 1.2%;
    width: 150%;
}

.editIcon {
    position: absolute !important;
    margin-top: 3px !important;
    right: 20px;
}

.areaDiv {
    position: relative;
    margin-left: 12px;
    margin-bottom: 15px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
}

.headline {
    font: message-box;
    font-size: 18px;
    margin-top: 10px;
    margin-left: 4px;
    font-weight: 550;
}