.root {
    padding: 0;
    width: 100%;
    bottom: 0;
}

.tableCell{
    font-size: 0.825rem;
    width: 18%!important;
}

.delete{
    font-size: 0.825rem;
    margin-right: 90%;
    width: 8%!important;
}

.tablePagination{
    display: flex;
    flex-direction: row;
    font-size: 0.1rem;
}

.visuallyHidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1;
    margin: -1;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1;
}