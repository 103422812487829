.collection_date {
   margin: 7px!important;
}
@media all and (max-width: 768px) and (min-width: 480px) {
    .collection_date{
        width: 60%!important;
    }
}

@media all and (max-width: 1024px) and (min-width: 768px) {
    .collection_date{
        width: 70%!important;
    }
}