@import "~react-image-gallery/styles/css/image-gallery.css";

.greenIcon {
  color: green!important; /* Change this to your desired green color */
}

.focusLayout {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.fullScreenLogoAdjust {
    border-style: solid;
    border-color: black;
    background-color: black;
}

.gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1000px;
    height: 500px;
    z-index: 2;
    background-color: #eaf2f4;
}

.galleryTwoImages {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%!important;
    height: 400px!important;
    z-index: 2;
    background-color: #eaf2f4;
}

.galleryWithGuide {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 450px;
    height: 500px;
    z-index: 2;
    background-color: #eaf2f4;
}

.galleryGuide {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 450px;
    height: 500px;
    z-index: 2;
    background-color: #eaf2f4;
}

.guideDescription {
    width: 450px;
    margin-top: 1%;
    z-index: 1;
    border-style: solid;
    border-radius: 1em;
    border-color: #deeaee;
}

.guideDescriptionText {
    text-align: center;
    text-overflow: ellipsis;
    line-height: 190%;
    overflow: clip;
}

.oneScaleBar {
    margin-top: 10%;
    height: 25px;
    position: absolute;
    bottom: 0.5%;
    z-index: 2;
}

.progressBarOneImage {
    margin-left: 55.5%;
}

.progressBarTwoImages {
    margin-left: 29%;
}

.progressBarWithGuide {
    margin-left: 13%;
}

.scaleBarGuide {
    height: 25px;
    background-color: rgba(245, 245, 245, 0.575);
    position: absolute;
    right: 32%;
    bottom: 1%;
    z-index: 2;
}

.sidebyside {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.topDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -1%;
}

.topDivTwoImages {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -2%;
}

.topDivWithGuide {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -2%;
}

.topDivGuide {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tools {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.twoScalesBar {
    height: 25px;
    background-color: rgba(245, 245, 245, 0.575);
    position: absolute;
    right: 32%;
    bottom: 1%;
    z-index: 2;
}

.zoomLayout {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(245, 245, 245, 0.575);
    position: absolute;
    right: 1.2%;
    bottom: 2%;
    z-index: 2;
}

.zoomLayoutGuide {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(245, 245, 245, 0.575);
    position: absolute;
    right: 1.2%;
    bottom: 3%;
    z-index: 2;
}

@media all and (max-width: 1000px) and (min-width: 600px) {
    .galleryTwoImages {
        width: 200px;
        height: 300px;
    }
    
}

@media all and (max-width: 1450px) and (min-width: 1000px) {
    .galleryTwoImages {
        width: 300px;
        height: 400px;
    }
}