.collectionInfo {
    width: 70%;
    margin-left: 15% !important;
    margin-top: 5%;
}

.collectionDate {
    width: 70%;
    margin-left: 15% !important;
    margin-top: 3% !important;
}

.frame {
    width: 30%;
    margin-left: 8%;
    margin-top: 2%;
}

.save {
    width: 50%;
    margin-top: 6% !important;
    margin-left: 25% !important;
    margin-bottom: 7% !important;
}

.container {
    display: flex;
    flex-direction: column;
}

.wrapper {
    display: flex;
    flex-direction: row;
    margin-top: -1%;
}

.wrapper1 {
    width: 80% !important;
    border-style: solid;
    border-radius: 3em;
    border-color: #11698E;
    margin-top: 4%;
    margin-left: 0%;
}

.wrapper2 {
    width: 100% !important;
    margin-right: 10%;
    margin-top: -2%;
    margin-left: 2%;
}

.mapDiv {
    height: 80vh;
    width: 45%;
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
}

.collection_id {
    width: 55.5%;
    margin-left: 12%;
    margin-top: 2%;
}

@media all and (max-width: 768px) and (min-width: 480px) {
    .collectionInfo {
        font-size: 1rem !important;
    }
}

@media all and (max-width: 1024px) and (min-width: 768px) {
    .collectionInfo {
        font-size: 1rem !important;
    }
}