/*------------------------------------------
  Responsive Grid Media Queries - 1280, 1024, 768, 480
   1280-1024   - desktop (default grid)
   1024-768    - tablet landscape
   768-480     - tablet 
   480-less    - phone landscape & smaller
--------------------------------------------*/
.spinner {
    position: absolute;
    left: 50%;
    top: 40%;
    border-radius: 100%;
    background: #ffffff !important;
}

.completed {
    background: #ffffff !important;
    border: none;
    margin: 1px !important;
}

.filtered {
    display: flex;
    flex-direction: column;
    position: relative;
    left: 14%;
    width: 15%;
    margin-left: 68% !important;
    bottom: 6% !important;
    z-index: 0;
}

.processing {
    background: #ffffff !important;
    border: none;
    margin: 1px !important;
}

.jumpToCollectionsDiv {
    position: fixed;
    right: 20px;
    top: 87px;
    display: flex;
    width: 20%;
    height: 34px;
    background: white;
    justify-content: space-around;
    z-index: 1;
    margin-top: -5px
}

.roadPageWrapper {
    display: flex;
    flex-direction: row;
}

.routeIcon {
    position: absolute!important;
    top: 55px;
    right: 6px;
    background-color: white!important;
    border-radius: 50%;
}

.landMarkIcon{
    position: absolute!important;
    top: 108px;
    right: 6px;
    background-color: white!important;
}

.zoomSelected{
    position: absolute!important;
    top: 160px;
    right: 6px;
    background-color: white!important;
}

.newLandmark{
    position: absolute!important;
    top: 211px;
    right: 6px;
    background-color: white!important;
}

.download{
    position: absolute!important;
    top: 265px;
    right: 6px;
    background-color: white!important;
}

.date{
    position: absolute!important;
    top: 270px;
    right: 60px;
    background-color: white!important;
}

.downloadDirections{
    position: absolute!important;
    top: 320px;
    right: 6px;
    background-color: white!important;
}

.downloadClients{
    position: absolute!important;
    top: 373px;
    right: 6px;
    background-color: white!important;
}

.csvIcon{
    position: absolute!important;
    top: 160px;
    right: 6px;
    background-color: white!important;
}

.searchIcon{
    position: absolute!important;
    top: 8px;
    left: 190px;
    background-color: white!important;
}

.survey{
    position: absolute!important;
    top: 8px;
    left: 240px;
    background-color: white!important;
}

.buttonSpan {
    margin: inherit;
}

.location {
    bottom: 9% !important;

}

.locationsOfTheMapDiv {
    display: flex;
    flex-direction: row;
    width: min-content;
    height: min-content;
    margin-top: 0.5%;
    margin-left: 16%;
    z-index: 1;
}

.mapDiv {
    display: flex;
    flex-direction: row;
    height: 96% !important;
    width: 205% !important;
    z-index: 1;
}

.negativeChange {
    color: green;
    display: flex;
    flex-direction: row;
}

.deployed {
    background: #ffffff !important;
    border: none;
    margin: 1px !important;
}

.positiveChange {
    color: red;
    display: flex;
    flex-direction: row;
}

.searchSite {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 20%;
    z-index: 10;
}

.siteInfo {
    display: flex;
    flex-direction: row;
    font-size: 1.5em;
    margin-top: -5%;
    margin-left: 8%;
    width: 100%;
}

.stateFilterButtonsDiv {
    align-items: center;
    width: 1%;
    height: 1%;
    margin-top: 0.5%;
    margin-left: 1% !important;
}

.zoomSelect {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 55%;
    height: 9%;
    margin-top: 1%;
    margin-left: 96.2% !important;
    background-color: white;
}

@media all and (max-width: 768px) and (min-width: 480px) {
    .jumpToCollectionsDiv {
        width: 27% !important;
        right: 1px !important;
    }
}


@media all and (max-width: 1200px) and (min-width: 768px) {
    .jumpToCollectionsDiv {
        width: 22% !important;
        right: 40px !important;
    }

}

@media all and (max-width: 950px) and (min-width: 480px) {
    .searchSite {
        font-size: 0.7rem !important;
        margin-top: 13% !important;
        margin-left: 2% !important;
        position: absolute !important;
    }
}