.description {
    font-size: 1.3em;
    line-height: 1.7;
    margin-top: 2%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.vials {
    width: 300px;
}

.vialInfo {
    width: 200px;
}

.wrapper1 {
    width: 80%;
    margin-top: 5%;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.save {
    width: 5%;
}

.prograssBar{
    margin-left: 40%;
    margin-top: 1%;
}