.container {
    display: flex;
    flex-direction: column;
    background-image: url('./background4.jpg');
    background-size: 100% 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.content {
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-radius: 2em;
    border-color: #11698E;
    background-color: white;
    opacity: 0.95;
    align-items: center;
    justify-content: center;
}

.title {
    padding: 1vw;
    font-size: 220%;
}

.loginBox {
    display: flex;
    width: 55%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loginBtn {
    width: 65%;
    margin-top: 5% !important;
    margin-bottom: 2% !important;
}

.nameField {
    width: 140%;
}

.passwordField {
    width: 140%;
}

.logo {
    width: 50%;
    height: 45%;
}

.alert {
    margin-top: 1%;
}

@media all and (max-width: 600px) and (min-width: 200px) {
    .title {
        font-size: 150% !important;
    }
    .content{

    }
}